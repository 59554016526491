import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`弊社について`}</h2>
    <p>{`弊社についてご関心をお寄せいただき、誠にありがとうございます。
弊社の企業情報については以下の通りとなります。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`企業名`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`株式会社OsaiFu (OsaiFu Inc.)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`代表社員`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`代表取締役 CEO 佐藤 正嶺 (Masane Sato)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`法人番号`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1130003006930`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`本店所在地`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`京都府京都市下京区四条通室町東入函谷鉾町101番地 アーバンネット四条烏丸ビル6階`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`本社所在地`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`京都府京都市中京区梅忠町24番地 三条COHJUビル4階`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`資本金`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1000千円(資本準備金を除く)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`設立`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020年12月`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`取引銀行`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`三菱UFJ銀行京都支店 みずほ銀行銀座支店`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      