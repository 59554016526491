import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`事業部署・プロダクトについて`}</h2>
    <ProjectCard title="Osa!Fu" link="#" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%);" mdxType="ProjectCard">
主に国際送金をメインとした、ワンストップ型のプラットフォームです。<br />
Coming soon... <br />
(Osa!Fu及びOsai-Fu、またそれに類する商標は2020年12月10日の設立日より商標申請準備中でございます)
    </ProjectCard>
    <ProjectCard title="ネットワーク関連事業" link="#" bg="linear-gradient(to right, #55B97D 0%, #FBB03B 100%)" mdxType="ProjectCard">
金融に関するセキュリティ技術・ネットワーク技術を応用し、ネットワーク関連事業を行っております。<br />
小規模オフィスのネットワーク構築や、安定性の高いライブ配信の運用などを行っています。
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      